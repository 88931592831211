import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Col, Row, Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { InstitutionsList, GetHolding, UpdateHolding } from '../../services/Api';

class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holding:{
                id:0,
                domains: '',
                name: '',
                config: {}
            },
            institutions: [],
            buttons:{
                save:true,
                apply:false
            },
            error:{
                save:true,
                apply:true,
                save_txt:'',
                apply_txt:''
            },
            loading:true
        };
        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async loadVars() {//Load CenterCost
        let holding = await GetHolding({id:this.props.match.params.id});
        InstitutionsList({holding_id:holding.id}).then((response) => {
            this.setState({ institutions: response });
        });

        this.setState({
            holding, 
            loading:false
        });
    }

    async componentDidMount() {
        this.loadVars().then(function(){
            const {holding} = this.state;
        }.bind(this))
    }

    onChange = ({target}) => {
        let {value} = target.value??null;
        const {holding} = this.state;
        switch (target.type){
            case 'checkbox':
                value = target.checked;
                break;
            default:
                value = target.value;
        }
        switch (target.name){
            case 'color':
                holding.config.app_identity.color = value;
            break;
            case 'framework_convention':
            case 'fulltime':
                holding.config.modules[target.name] = value;
            break;
            case 'publication':
                if (!value) {
                    holding.config.modules[target.name] = value;
                } else {
                    holding.config.modules[target.name] = {"postulation_coefs":{"career":0.1,"grade":0.1,"technical":0.6,"behavioural":0.2}}
                }
            break;
            case 'career':
            case 'grade':
            case 'technical':
            case 'behavioural':
                holding.config.modules.publication.postulation_coefs[target.name] = value;
            break;
            default:
                holding[target.name] = value;
        }
        this.setState({holding});
    }

    async save (){
        const {holding} = this.state;
        const response = await UpdateHolding(holding);
        window.history.pushState({},"",'/');
        this.props.history.push('/');
    }

    render() {
        const {
            institutions,
            holding,
            error,
            loading,
            options
        } = this.state;
        const { onChange } = this;
        return (!loading&&holding)?(
            <>
                <ToastContainer 
                autoClose={5000}
                pauseOnHover
                />
                <Row as="form">
                    <Col>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                        placeholder="Nombre"
                        name="name"
                        defaultValue={holding.name}
                        onChange={onChange}
                        isInvalid={error.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            Debe introducir un nombre
                        </Form.Control.Feedback>
                    </Col>
                    <Col>
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                        placeholder="ej.: uah"
                        name="slug"
                        defaultValue={holding.slug}
                        onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Dominios</Form.Label>
                        <Form.Control placeholder="listado de dominios separados por coma"
                        name="domains"
                        defaultValue={holding.domains}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Color base</Form.Label>
                        <Form.Control type="color" name="color" value={holding.config.app_identity.color} onChange={onChange} width="10%"></Form.Control>
                    </Col>
                </Row>
                <Row>
                    <h3>Módulos</h3>
                </Row>
                <Row>
                    <Col>
                        <Form.Check type="checkbox" name="fulltime" checked={holding.config.modules.fulltime} onChange={onChange} label="Planta" className="mr-2"/>
                        <Form.Check type="checkbox" name="framework_convention" checked={holding.config.modules.framework_convention} onChange={onChange} label="Convenios Marco" className="mr-2"/>
                        <Form.Check type="checkbox" name="publication" checked={holding.config.modules.publication} onChange={onChange} label="Publicación" className="mr-2"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Coef. Título</Form.Label>
                        <Form.Control placeholder="de 0-1"
                        name="career"
                        defaultValue={holding.config.modules.publication.postulation_coefs?.career}
                        disabled={!holding.config.modules.publication}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Coef. Grado</Form.Label>
                        <Form.Control placeholder="de 0-1"
                        name="grade"
                        defaultValue={holding.config.modules.publication.postulation_coefs?.grade}
                        disabled={!holding.config.modules.publication}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Coef. Técnico</Form.Label>
                        <Form.Control placeholder="de 0-1"
                        name="technical"
                        defaultValue={holding.config.modules.publication.postulation_coefs?.technical}
                        disabled={!holding.config.modules.publication}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Coef. Conductual</Form.Label>
                        <Form.Control placeholder="de 0-1"
                        name="behavioural"
                        defaultValue={holding.config.modules.publication.postulation_coefs?.behavioural}
                        disabled={!holding.config.modules.publication}
                        onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right mt-3">
                        <Button variant="success" readOnly onClick={this.save} as="input" value="Guardar"/>
                    </Col>
                </Row>
                <Row>
                    <h3>Instituciones</h3>
                    <table className="table table-borderless text-center">
                            <thead>
                                <tr>
                                    <th>
                                        Nombre
                                    </th>
                                    <th>
                                        Rut
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {institutions && institutions.map((nt) => (
                                    <tr key={nt.id}>
                                        <td>
                                            {nt.name}
                                        </td>
                                        <td>
                                            {nt.rut_legal}
                                        </td>
                                        <td>
                                            <Link
                                                className="btn btn-success btn-sm mr-3"
                                                to={'/institution/edit/' + nt.id}
                                            > <i className="fa fa-pencil"></i>
                                            </Link>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                </Row>
            </>
        ):(
            <div className="text-center col pt-5">
                <Spinner />
                <h5>Cargando datos..</h5>
            </div>
        );
    }
}
export default Update;
Update.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};